.fipListRoot {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: var(--root-height);
  box-sizing: border-box;
}
.fipListBody {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  height: calc(100% - 50px);
}
.textHeader {
  font-size: 24px;
  font-weight: 600;
  color: var(--header-text-color);
}
.textDescription {
  color: var(--description-text-color);
}
.allFipsContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
  cursor: pointer;
}
.popularFipsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.popularFipsCard {
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(33% - 6px);
  aspect-ratio: 1/1;
  max-height: 125px;
  box-sizing: border-box;
  gap: 10px;
  cursor: pointer;
  border-radius: 16px;
  background-color: var(--card-color);
}
.bankName {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  width: 70%;
  color: gray;
  text-align: center;
}
.selectedBankName {
  font-weight: 600;
  color: var(--header-text-color);
}
.secondaryHeader {
  color: var(--header-text-color);
}
.selectedPopularFipsCard {
  border-color: var(--selected-border-color);
  border-width: 2px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.bankInputSearch::before {
  content: '';
}
.header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: sticky;
  top: 0;
  background: var(--primary-backgroud-color);
  z-index: 5;
  padding-bottom: 10px;
}
.allBanksHeader {
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 5;
  position: sticky;
  top: 61px;
  background: var(--primary-backgroud-color);
  padding: 10px 0;
}
.bankInputSearch {
  border: none;
  width: 100%;
  background: var(--primary-backgroud-color);
  color: var(--text-color);
}
.bankInputSearch::placeholder {
  color: var(--text-color);
}
.bankInputSearch:focus-visible {
  outline: none;
}
.searchContainer {
  border: 1px solid rgb(201, 201, 201);
  padding: 10px;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.primaryButton {
  color: var(--primary-button-text-color);
  background: var(--primary-button-color);
  border: none;
  padding: 12px;
  border-radius: var(--button-border-radius);
  font-weight: 600;
}
.primaryButton:disabled {
  background: var(--disabled-button-color);
}

.flowSelectionContainer {
  padding: 15px;
}
.flowSelectionContainer h2 {
  color: var(--text-color);
}
.flowSelection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.method {
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px solid var(--border-color);
  background-color: var(--card-color);
}

.methodIcon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  color: var(--text-color);
}

.methodArrow img {
  width: 20px;
  height: 20px;
}

.methodInfo h2 {
  font-size: 1em;
}

.methodInfo p {
  margin: 5px 0;
  font-size: 0.9em;
  color: #6c757d;
}

.verificationType {
  display: block;
  font-size: 0.8em;
  color: #6c757d;
  margin-top: 10px;
}
.flowSelectionHeader {
  color: var(--header-text-color);
}
.rightIcon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  color: var(--text-color);
}

.popular_banks {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  margin-bottom: 16px;
}

.popular_banks .bank_logo {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popular_banks .bank_logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.bank_card {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 12px;
}

.bank_card img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-bottom: 8px;
}

.bank_card p {
  font-size: 10px;
  color: var(--text-color); /* PB-Text Color */
  margin: 0;
  line-height: 1.2;
}
.bank_logo_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 50%;
  border: 1px solid rgba(27, 29, 199, 0.1);
}

.selected {
  border: 1px solid var(--primary-color);
}

.other_banks {
  display: flex;
  flex-direction: column;
}

.bank_item {
  display: flex;
  align-items: center;
  background-color: var(--white); /* PB-White */
  border-bottom: 1px solid rgba(27, 29, 199, 0.1);
  padding: 4px 0;
}

.bank_item img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.bank_item p {
  flex-grow: 1;
  font-size: var(--font-size-12);
  color: var(--text-color); /* PB-Text Color */
  margin-left: 8px;
}

.arrow {
  width: 16px;
  height: 16px;
}

.all_other_banks {
  background-color: var(--white); /* PB-White */
  border-radius: 0.75rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.all_other_banks img {
  width: 32px;
  height: 32px;
  margin-right: 1rem;
}

.all_other_banks p {
  flex-grow: 1;
  font-size: 16px;
  color: var(--text-color);
}
.section_title {
  font-size: 16px;
  font-weight: 700;
  color: var(--text-color);
}
.section_sub_title {
  font-weight: 600;
  font-size: 12px;
  opacity: 0.7;
}
.si_creva_info_box {
  padding: 18px 8px;
  height: fit-content;
  background-color: #e3e9ff;
  color: #131523;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}
